import { Dispatch, SetStateAction } from 'react';

import Grid from '@mui/material/Grid';

import { onPushEvent } from '@/services/lib/gtm';
import TimeDrawer from '@/components/TimeRange/Drawer';
import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';
import ProgramSelector from '@/components/Loyalty/ProgramSelector';

const Filters = ({ setTimeRange }: { setTimeRange: Dispatch<SetStateAction<ITimeRangeForm>> }) => {
    const todayDate = new Date();
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90);

    return (
        <>
            <Grid
                item
                sm={(12 / 5) * 2}
                md={(12 / 5) * 2}
                lg={(12 / 5) * 2}
                xl={(12 / 5) * 2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <ProgramSelector />
            </Grid>
            <Grid item xs={12} sm={(12 / 5) * 3} md={(12 / 5) * 3} lg={(12 / 5) * 3} xl={(12 / 5) * 3}>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item xs={12} sm={4}>
                        <TimeDrawer
                            onConfirm={(val) => {
                                onPushEvent(`user_use_filter_date_${val.type}`);
                                setTimeRange(val);
                            }}
                            options={[
                                TimeRangeTypes.TODAY,
                                TimeRangeTypes.YESTERDAY,
                                TimeRangeTypes.WEEK,
                                TimeRangeTypes.LAST_7_DAYS,
                                TimeRangeTypes.MONTH,
                                TimeRangeTypes.LAST_30_DAYS,
                            ]}
                            minDate={minDate}
                            timeSelectEnabled
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Filters;
