import { useState } from 'react';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { useLoyaltyContext } from '@/contexts/loyalty';
import { ILoyaltyProgram } from '@/interfaces/loyalty/types';

export default function ProgramSelector() {
    const { programs, selectedProgram, setSelectedProgram } = useLoyaltyContext();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'programs-popover' : undefined;

    function handleProgramClick(program: ILoyaltyProgram) {
        if (setSelectedProgram !== null) setSelectedProgram(program);
        handleClose();
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography fontWeight={500}>{selectedProgram?.name}</Typography>
            <IconButton
                aria-describedby={id}
                sx={{ backgroundColor: 'rgba(0,0,0,.05)', width: '16px', height: '16px' }}
                size="small"
                onClick={handleClick}
            >
                <ExpandMoreOutlined
                    sx={{
                        fontSize: '16px',
                        rotate: anchorEl ? '180deg' : '0deg',
                        transition: 'all ease-in-out 300ms',
                    }}
                />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        marginTop: '6px',
                        minWidth: '252px',
                        boxShadow: '0px 3px 14px 0px rgba(0, 0, 0, 0.08)',
                        borderRadius: '20px',
                        maxHeight: '300px',
                    },
                }}
            >
                <Stack gap={0.5} paddingY={1.2}>
                    {programs.map((program) => (
                        <Button
                            key={program.id}
                            sx={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                borderRadius: '0px',
                                padding: '8px 16px',
                                fontWeight: 500,
                                color: 'rgba(0,0,0,.87)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,.05)',
                                },
                            }}
                            onClick={() => handleProgramClick(program)}
                        >
                            {program.name}
                        </Button>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
}
