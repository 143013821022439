import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import DashboardCard from '@/components/Dashboard/DashboardCard';
import { FilterProps } from '@/views/Dashboard';
import { useTranslation } from '@/hooks/translations';
import DashboardService from '@/services/dashboard';
import { useLoyaltyContext } from '@/contexts/loyalty';
import { useRestaurantContext } from '@/contexts/restaurant';
import { IFetchDashboardDataResponse } from '@/components/Dashboard/types';
import { timeRangeTitles } from '@/components/TimeRange/Form/hooks/useData';

function LoyaltyInfoCards({ timeRange }: FilterProps): JSX.Element {
    const { t } = useTranslation('common');
    const dashboardService = DashboardService.getInstance();
    const { selectedProgram } = useLoyaltyContext();
    const { restaurant } = useRestaurantContext();
    const [dashboardData, setDashboardData] = useState<IFetchDashboardDataResponse>();
    const [dashboardItemsLoading, setDashboardItemsLoading] = useState<boolean>(false);
    const [dashboardItemsError, setDashboardItemsError] = useState<string>('');

    const range = timeRangeTitles(t)[timeRange.type];

    const getData = () => {
        dashboardService
            .getLoyaltyDashboardData({
                id: restaurant?.id,
                period: timeRange.type,
                from: timeRange.from,
                to: timeRange.to,
                isLegacy: true,
                programId: selectedProgram?.id,
            })
            .then((res) => {
                setDashboardData(res);
            })
            .catch((err) => {
                setDashboardItemsError(err.message);
            })
            .finally(() => {
                setDashboardItemsLoading(false);
            });
    };

    useEffect(() => {
        setDashboardItemsLoading(true);
        getData();
    }, [timeRange, selectedProgram?.id]);

    return (
        <>
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DashboardCard
                            headerText={`${t(`Redemption`)} ${range}`}
                            value={`${
                                dashboardData?.cards.find((item) => item.title === 'burnPoints')?.value ?? 0
                            } PTS`}
                            tooltipText={t('Total_Redemption_Selected_Period_TooltipText')}
                            loading={dashboardItemsLoading}
                            error={dashboardItemsError}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DashboardCard
                            headerText={t(`Redemption Compared last week`)}
                            value={`${
                                (dashboardData?.cards?.find((item: any) => item.title === 'burnPoints') as any)
                                    ?.changeRate ?? 0
                            } %`}
                            tooltipText={t('Total_Redemption_Compared_Selected_Period_TooltipText')}
                            loading={dashboardItemsLoading}
                            error={dashboardItemsError}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DashboardCard
                            headerText={`${t(`Earned`)} ${range}`}
                            value={`${
                                dashboardData?.cards.find((item) => item.title === 'earnPoints')?.value ?? 0
                            } PTS`}
                            tooltipText={t('Total_Earned_Selected_Period_TooltipText')}
                            loading={dashboardItemsLoading}
                            error={dashboardItemsError}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DashboardCard
                            headerText={t(`Earned Compared last week`)}
                            value={`${
                                (dashboardData?.cards?.find((item: any) => item.title === 'earnPoints') as any)
                                    ?.changeRate ?? 0
                            } %`}
                            tooltipText={t('Total_Earned_Compared_Selected_Period_TooltipText')}
                            loading={dashboardItemsLoading}
                            error={dashboardItemsError}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default LoyaltyInfoCards;
